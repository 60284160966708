<template>
  <div>
    <b-alert variant="secondary" show>
      <div
        style="display: flex; align-items: end; justify-content: end"
        class="mb-1 alert-heading"
      >
        <p class="mr-1"><b> Invoice Date Range (current month):</b></p>
        <flat-pickr
          @input="onReportDate"
          v-model="reportDateRange"
          style="width: 400px"
          :config="{ mode: 'range', ...$flatPickrConfig }"
          placeholder="Select Month Range"
          class="form-control mr-1"
        />
        <b-button
          @click="showModal"
          class="mr-1"
          v-b-tooltip.hover
          title="apply filters"
          variant="secondary"
          size="sm"
          ><feather-icon style="cursor: pointer" icon="FilterIcon" size="23"
        /></b-button>
        <b-button
          v-b-tooltip.hover
          title="export as excel"
          variant="secondary"
          size="sm"
          @click="onExportExcel"
          ><feather-icon style="cursor: pointer" icon="DownloadIcon" size="23"
        /></b-button>
      </div>
    </b-alert>
    <div v-if="filterApplied == true">
      <b-badge
        class="mr-1"
        variant="secondary"
        v-if="filters.selectedInvoiceArray.length"
      >
        Invoice No : {{ filters.selectedInvoiceArray.toString() }}
      </b-badge>
    </div>
    <div style="overflow-x: auto">
      <table ref="exportable_table">
        <tr style="background: lightgrey">
          <th>
            Invoice Number
            <hr />
            <b-button
              @click="showModal"
              v-b-tooltip.hover
              title="apply filters"
              variant="secondary"
              size="sm"
              ><feather-icon
                style="cursor: pointer"
                icon="FilterIcon"
                size="15"
            /></b-button>
          </th>

          <th>
            Account
            <hr />

            <b-button
              @click="showModal"
              v-b-tooltip.hover
              title="apply filters"
              variant="secondary"
              size="sm"
              ><feather-icon
                style="cursor: pointer"
                icon="FilterIcon"
                size="15"
            /></b-button>
          </th>
          <th>Revenue Name</th>
          <th>Invoice Date</th>
          <th>Payment Date</th>
          <th>
            Currency
            <hr />

            <b-button
              @click="showModal"
              v-b-tooltip.hover
              title="apply filters"
              variant="secondary"
              size="sm"
              ><feather-icon
                style="cursor: pointer"
                icon="FilterIcon"
                size="15"
            /></b-button>
          </th>
          <th>Invoice Net Amount (excl. VAT)</th>
          <th>VAT (Accounting Code)</th>
          <th>VAT Percent</th>
          <th>VAT Amount</th>
          <th>Invoice Amount (incl. VAT)</th>
        </tr>
        <tr
          v-for="(item, index) in bookKeepingReportData"
          :key="index"
          :style="
            item.invoice_unique_id &&
            item.invoice_unique_id.toString().includes('Total')
              ? 'background:lightGrey;border-bottom:3px solid grey '
              : ''
          "
        >
          <td>
            <a
              @click="
                () =>
                  (invId = item.revenue_invoice_id
                    ? item.revenue_invoice_id
                    : null)
              "
              v-b-modal.invModal
              ><u>{{ item.invoice_unique_id }}</u></a
            >
          </td>
          <td>{{ item.iban }}</td>
          <td>{{ item.additional_cost_name }}</td>
          <td>{{ item.invDate ? item.invDate : "" }}</td>
          <td>{{ item.payDate ? item.payDate : "" }}</td>
          <td>{{ item.selectedCurrency }}</td>
          <td style="text-align: right">
            {{ formatCurrency(item.amount) }}
          </td>
          <td>{{ item.vatCode }}</td>
          <td>{{ item.vatPercent }}</td>
          <td style="text-align: right">{{ formatCurrency(item.vatAmt) }}</td>
          <td style="text-align: right">{{ formatCurrency(item.totalAmt) }}</td>
        </tr>
      </table>
    </div>
    <div>
      <b-modal
        no-close-on-backdrop
        ref="my-modal"
        hide-footer
        title="Report Filters"
      >
        <div class="d-block text-left">
          <b-form-group id="Invoice" label="Invoice Numbers">
            <v-select
              :close-on-select="true"
              multiple
              v-model="filters.selectedInvoiceArray"
              placeholder="Select"
              label="value"
              :reduce="(inv) => inv.id"
              :options="invoiceNumbersArray"
            />
          </b-form-group>
          <b-form-group id="Account" label="Account">
            <v-select
              :close-on-select="true"
              multiple
              v-model="filters.selectedaccArray"
              placeholder="Select"
              label="value"
              :reduce="(inv) => inv.id"
              :options="accNumbersArray"
            />
          </b-form-group>
          <b-form-group id="Currency" label="Currency">
            <v-select
              :close-on-select="true"
              multiple
              v-model="filters.selectedCurrencyArray"
              placeholder="Select"
              label="value"
              :reduce="(inv) => inv.id"
              :options="currencyArray"
            />
          </b-form-group>
          <!-- <b-form-group label="Invoice Date Range">
            <flat-pickr
              :config="{ mode: 'range', ...$flatPickrConfig }"
              placeholder="Select"
              class="form-control"
              v-model="filters.selectedInvDateRange"
            />
          </b-form-group> -->
        </div>
        <b-button
          class="mt-3"
          variant="outline-danger"
          block
          @click="onApplyFilters"
          >Apply Filters</b-button
        >
        <b-button variant="outline-info" block @click="onResetFilters"
          >Reset Filters</b-button
        >
      </b-modal>
    </div>
    <b-modal v-if="invId" size="xl" id="invModal" title="Invoice"
      ><preview-invoice :invId="invId" />
    </b-modal>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BButton,
  VBTooltip,
  BModal,
  BFormGroup,
  BBadge,
  BAlert,
} from "bootstrap-vue";
import { GetAdditionalRevBookKeepingRevenueReport } from "@/apiServices/BookkeepingServices";
import moment from "moment";
import { formatCurrency } from "@/utils/helpers";
import qs from "qs";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import PreviewInvoice from "@/views/pages/Revenue/PreviewInvoice.vue";
export default {
  data() {
    return {
      filterApplied: false,
      reportDateRange: null,
      reportFromDate: null,
      reportToDate: null,
      invoiceNumbersArray: [],
      movieArray: [],
      territoryArray: [],
      rightArray: [],
      uniqueIdArray: [],
      accNumbersArray: [],
      currencyArray: [],
      filters: {
        selectedInvoiceArray: [],
        selectedMovieArray: [],
        selectedaccArray: [],
        selectedCurrencyArray: [],
        selectedTerArray: [],
        selectedUniqueIdArray: [],
        selectedRightArray: [],
        selectedInvDateRange: null,
      },
      BASE_URL: process.env.VUE_APP_BASEURL,
      moment: moment,
      tabIndex: 0,
      bookKeepingReportData: [],
      actualBookKeepingReportData: [],
      invId: null,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BTabs,
    BTab,
    BCard,
    FeatherIcon,
    BButton,
    VBTooltip,
    BModal,
    BFormGroup,
    vSelect,
    flatPickr,
    BBadge,
    PreviewInvoice,
    BAlert,
  },
  beforeMount() {
    this.filterApplied = false;
    let today = moment().format("YYYY-MM-DD");
    let previous = moment().startOf("month").format("YYYY-MM-DD");
    this.reportDateRange = previous + " to " + today;

    this.reportFromDate = moment().startOf("month").format("YYYY-MM-DD");
    this.reportToDate = moment().endOf("month").format("YYYY-MM-DD");
    this.getBookkeepingReport();
  },
  methods: {
    formatCurrency,
    onReportDate() {
      if (this.reportDateRange) {
        let from = null;
        let to = null;
        if (this.reportDateRange.split("to").length > 1) {
          from = new Date(this.reportDateRange.split("to")[0]);
          to = new Date(this.reportDateRange.split("to")[1]);
        } else {
          from = new Date(this.reportDateRange.split("to")[0]);
          to = new Date(this.reportDateRange.split("to")[0]);
        }

        this.reportFromDate = `${from.getFullYear()}-${(
          "0" +
          (from.getMonth() + 1)
        ).slice(-2)}-${("0" + from.getDate()).slice(-2)}`;

        // this.reportToDate = `${to.getFullYear()}-${(
        //   "0" +
        //   (to.getMonth() + 1)
        // ).slice(-2)}-${(
        //   "0" + (to.getDate() + to.getMonth() == 2 ? 28 : 30)
        // ).slice(-2)}`;
        this.reportToDate = moment(to).add(1, "days").format("YYYY-MM-DD");
        this.getBookkeepingReport();
      }

      //       const parsedDate = moment(dateString, 'MMM-YYYY');

      // // Step 2: Format the parsed date into YYYY-MM-DD format
      // const formattedDate = parsedDate.format('YYYY-MM-DD');
    },
    onResetFilters() {
      this.filterApplied = false;
      this.uniqueIdArray = [];
      this.territoryArray = [];
      this.rightArray = [];
      this.movieArray = [];
      this.uniqueIdArray = [];
      this.invoiceNumbersArray = [];
      this.licensorArray = [];
      this.currencyArray = [];
      this.filters = {
        selectedInvoiceArray: [],
        selectedaccArray: [],
        selectedCurrencyArray: [],
        selectedInvDateRange: null,
        selectedMovieArray: [],
        selectedUniqueIdArray: [],
        selectedRightArray: [],
        selectedTerArray: [],
      };
      this.getBookkeepingReport();
    },
    onApplyFilters() {
      this.filterApplied = true;
      this.bookKeepingReportData = this.actualBookKeepingReportData;

      if (this.filters.selectedInvoiceArray.length) {
        this.bookKeepingReportData = this.bookKeepingReportData.filter((p) => {
          return this.filters.selectedInvoiceArray.includes(
            p.invoice_unique_id
          );
        });
      }
      if (this.filters.selectedMovieArray.length) {
        this.bookKeepingReportData = this.bookKeepingReportData.filter((p) => {
          return this.filters.selectedMovieArray.includes(p.title);
        });
      }

      if (this.filters.selectedUniqueIdArray.length) {
        this.bookKeepingReportData = this.bookKeepingReportData.filter((p) => {
          return this.filters.selectedUniqueIdArray.includes(p.uniqueId);
        });
      }
      if (this.filters.selectedTerArray.length) {
        this.bookKeepingReportData = this.bookKeepingReportData.filter((p) => {
          return this.filters.selectedTerArray.includes(p.territory);
        });
      }
      if (this.filters.selectedRightArray.length) {
        this.bookKeepingReportData = this.bookKeepingReportData.filter((p) => {
          return this.filters.selectedRightArray.includes(p.rightIdentifier);
        });
      }
      if (this.filters.selectedaccArray.length) {
        this.bookKeepingReportData = this.bookKeepingReportData.filter((p) => {
          return this.filters.selectedaccArray.includes(p.iban);
        });
      }
      if (this.filters.selectedCurrencyArray.length) {
        this.bookKeepingReportData = this.bookKeepingReportData.filter((p) => {
          if (p.selectedCurrency) {
            return this.filters.selectedCurrencyArray.includes(
              p.selectedCurrency
            );
          }
        });
      }
      if (this.filters.selectedInvDateRange) {
        if (this.filters.selectedInvDateRange.includes("to")) {
          let fromDate = this.filters.selectedInvDateRange.split("to")[0];
          let toDate = this.filters.selectedInvDateRange.split("to")[1];

          this.bookKeepingReportData = this.bookKeepingReportData.filter(
            (p) => {
              return (
                p.invDate >= moment(new Date(fromDate)).format("DD/MM/YYYY") &&
                p.invDate <= moment(new Date(toDate)).format("DD/MM/YYYY")
              );
            }
          );
        } else {
          let dt = this.filters.selectedInvDateRange;
          this.bookKeepingReportData = this.bookKeepingReportData.filter(
            (p) => {
              return p.invDate == moment(new Date(dt)).format("DD/MM/YYYY");
            }
          );
        }
      }

      let dataArray = [];
      let netTotal = 0;
      let totalWithVat = 0;
      let totalVatAmt = 0;
      let netTotalE = 0;
      let totalWithVatE = 0;
      let totalVatAmtE = 0;
      let netTotalU = 0;
      let totalWithVatU = 0;
      let totalVatAmtU = 0;
      for (let d of this.bookKeepingReportData) {
        if (d.vatCode) {
          d.vatCode = d.vatCode;
        }
        if (d.amount) {
          d.amount = parseFloat(d.amount ? d.amount : 0);
        }

        if (d.vatPercent) {
          let vatDecimal = parseFloat(d.vatPercent) / 100;

          d.vatAmt =
            parseFloat(d.amount ? d.amount : 0) *
            parseFloat(vatDecimal ? vatDecimal : 0);
          d.totalAmt =
            parseFloat(d.amount ? d.amount : 0) +
            parseFloat(d.vatAmt ? d.vatAmt : 0);
        } else {
          d.vatPercent = 0;
          d.vatAmt = 0;
          d.totalAmt = parseFloat(d.amount ? d.amount : 0);
        }
        d.vatDecimal = parseFloat(d.vatPercent);

        if (d.selectedCurrency == "CHF") {
          totalVatAmt += parseFloat(d.vatAmt ? d.vatAmt : 0);
          netTotal += parseFloat(d.amount ? d.amount : 0);
          totalWithVat += parseFloat(d.totalAmt ? d.totalAmt : 0);
        } else if (d.selectedCurrency == "USD") {
          totalVatAmtU += parseFloat(d.vatAmt ? d.vatAmt : 0);
          netTotalU += parseFloat(d.amount ? d.amount : 0);
          totalWithVatU += parseFloat(d.totalAmt ? d.totalAmt : 0);
        } else if (d.selectedCurrency == "EUR") {
          totalVatAmtE += parseFloat(d.vatAmt ? d.vatAmt : 0);
          netTotalE += parseFloat(d.amount ? d.amount : 0);
          totalWithVatE += parseFloat(d.totalAmt ? d.totalAmt : 0);
        } else {
          console.log("new currency");
        }

        dataArray.push(d);
      }

      let TotalSection = {
        invoice_unique_id: "Total CHF",
        additional_cost_name: "",
        invDate: "",
        payDate: "",
        amount: netTotal,
        vatPercent: "",
        vatCode: "",
        vatAmt: totalVatAmt,
        totalAmt: totalWithVat,
      };
      let finalGrandTotalEUR = {
        invoice_unique_id: "Total EUR",
        amount: netTotalE,
        vatAmt: totalVatAmtE,
        totalAmt: totalWithVatE,
      };
      let finalGrandTotalUSD = {
        invoice_unique_id: "Total USD",
        amount: netTotalU,
        vatAmt: totalVatAmtU,
        totalAmt: totalWithVatU,
      };
      dataArray.push(TotalSection);
      dataArray.push(finalGrandTotalUSD);
      dataArray.push(finalGrandTotalEUR);

      this.bookKeepingReportData = dataArray;
      console.log(this.bookKeepingReportData, " this.bookKeepingReportData");
      this.$refs["my-modal"].hide();
    },
    onExportExcel() {
      let url = null;
      let excelName = "";
      let payload = {
        bookKeepingReportData: this.bookKeepingReportData,
        uniqueId: this.filters.selectedUniqueIdArray
          ? this.filters.selectedUniqueIdArray.toString()
          : "",
        mvi: this.filters.selectedMovieArray
          ? this.filters.selectedMovieArray.toString()
          : "",
        invDtRange: this.filters.selectedInvDateRange,
        terr: this.filters.selectedTerArray
          ? this.filters.selectedTerArray.toString()
          : "",
        right: this.filters.selectedRightArray
          ? this.filters.selectedRightArray.toString()
          : "",
        invNumbers: this.filters.selectedInvoiceArray
          ? this.filters.selectedInvoiceArray.toString()
          : "",
      };

      url = this.BASE_URL + `/bookkeeping/exportAddRevBookKeepingRevenueReport`;

      fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/Json",
          authorization: localStorage.getItem("access_token"),
          "x-access-token": localStorage.getItem("access_token"),
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.blob())
        .then((blob) => {
          const anchorElement = document.createElement("a");
          document.body.appendChild(anchorElement);
          anchorElement.style.display = "none";
          excelName = "AddRevBookKeepingRevenueReport.xlsx";
          var url = window.URL.createObjectURL(blob);
          anchorElement.href = url;
          anchorElement.download = excelName;
          anchorElement.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err, "error in onExportExcel");
        });
    },
    async getBookkeepingReport() {
      try {
        this.bookKeepingReportData = [];
        const response = await GetAdditionalRevBookKeepingRevenueReport({
          reportFromDate: this.reportFromDate,
          reportToDate: this.reportToDate,
        });
        if (response.data.status) {
          this.bookKeepingReportData = response.data.data;

          this.actualBookKeepingReportData = response.data.data;

          let addedInv = [];
          let addedMovie = [];
          let addedUnId = [];
          let addedTer = [];
          let addedRt = [];
          let addedCurrency = [];
          let addedAcc = [];
          for (let item of this.bookKeepingReportData) {
            if (
              item.invoice_unique_id &&
              item.invoice_unique_id != "Total" &&
              item.invoice_unique_id != "Grand Total"
            ) {
              if (!addedInv.includes(item.invoice_unique_id)) {
                let obj = {
                  id: item.invoice_unique_id,
                  value: item.invoice_unique_id,
                };
                this.invoiceNumbersArray.push(obj);
                addedInv.push(item.invoice_unique_id);
              }
              if (!addedMovie.includes(item.title)) {
                let obj1 = {
                  id: item.title,
                  value: item.title,
                };
                this.movieArray.push(obj1);
                addedMovie.push(item.title);
              }
              if (!addedUnId.includes(item.uniqueId)) {
                let objU = {
                  id: item.uniqueId,
                  value: item.uniqueId,
                };
                this.uniqueIdArray.push(objU);
                addedUnId.push(item.uniqueId);
              }

              if (!addedRt.includes(item.rightIdentifier)) {
                let objR = {
                  id: item.rightIdentifier,
                  value: item.rightIdentifier,
                };
                this.rightArray.push(objR);
                addedRt.push(item.rightIdentifier);
              }
              if (!addedAcc.includes(item.iban)) {
                let obj66 = {
                  id: item.iban,
                  value: item.iban,
                };
                this.accNumbersArray.push(obj66);
                addedAcc.push(item.iban);
              }
              if (item.selectedCurrency) {
                if (!addedCurrency.includes(item.selectedCurrency)) {
                  let obj2 = {
                    id: item.selectedCurrency,
                    value: item.selectedCurrency,
                  };
                  this.currencyArray.push(obj2);
                  addedCurrency.push(item.selectedCurrency);
                }
              }

              if (!addedTer.includes(item.territory)) {
                let objT = {
                  id: item.territory,
                  value: item.territory,
                };
                this.territoryArray.push(objT);
                addedTer.push(item.territory);
              }
            }
          }
        }
        console.log(response, "response");
      } catch (error) {
        console.log("Error in getBookkeepingReport", error);
      }
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-primary", "text-light"];
      } else {
        return ["bg-outline-primary", "text-info"];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  color: #000;
  border: 2px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-weight: 550;
  font-weight: 400;
}
tr:hover {
  background-color: yellow;
}
</style>
