<template>
  <div>
    <b-alert variant="secondary" show>
      <div
        style="display: flex; align-items: end; justify-content: end"
        class="mb-1 alert-heading"
      >
        <!-- <p class="mb-1">Invoice Date Range :</p> -->
        <p class="mr-1"><b>Received Payment Date Range (current month):</b></p>
        <flat-pickr
          @input="onReportDate"
          v-model="reportDateRange"
          style="width: 400px"
          :config="{ mode: 'range', ...$flatPickrConfig }"
          placeholder="Select
      Month Range"
          class="form-control mr-1"
        />
        <b-button
          @click="showModal"
          class="mr-1"
          v-b-tooltip.hover
          title="apply filters"
          variant="secondary"
          size="sm"
          ><feather-icon style="cursor: pointer" icon="FilterIcon" size="23"
        /></b-button>
        <b-button
          v-b-tooltip.hover
          title="export as excel"
          variant="secondary"
          size="sm"
          @click="onExportExcel"
          ><feather-icon style="cursor: pointer" icon="DownloadIcon" size="23"
        /></b-button>
      </div>
    </b-alert>
    <div v-if="filterApplied == true">
      <b-badge
        class="mr-1"
        variant="secondary"
        v-if="filters.selectedAccountArray.length"
      >
        Account : {{ filters.selectedAccountArray.toString() }}
      </b-badge>
      <b-badge
        class="mr-1"
        variant="secondary"
        v-if="filters.selectedInvoiceArray.length"
      >
        Invoice No : {{ filters.selectedInvoiceArray.toString() }}
      </b-badge>
      <b-badge
        class="mr-1"
        variant="secondary"
        v-if="filters.selectedInvDateRange"
      >
        Added Payment Date : {{ filters.selectedInvDateRange }}
      </b-badge>
      <b-badge
        class="mr-1"
        variant="secondary"
        v-if="filters.selectedLicensorArray.length"
      >
        Licensor : {{ filters.selectedLicensorArray.toString() }}
      </b-badge>
    </div>
    <div style="overflow-x: auto">
      <table ref="exportable_table">
        <tr style="background: lightgrey">
          <th>
            Account
            <hr />

            <b-button
              @click="showModal"
              v-b-tooltip.hover
              title="apply filters"
              variant="secondary"
              size="sm"
              ><feather-icon
                style="cursor: pointer"
                icon="FilterIcon"
                size="15"
            /></b-button>
          </th>
          <th>
            Invoice Number
            <hr />

            <b-button
              @click="showModal"
              v-b-tooltip.hover
              title="apply filters"
              variant="secondary"
              size="sm"
              ><feather-icon
                style="cursor: pointer"
                icon="FilterIcon"
                size="15"
            /></b-button>
          </th>
          <th>
            Licensor
            <hr />

            <b-button
              @click="showModal"
              v-b-tooltip.hover
              title="apply filters"
              variant="secondary"
              size="sm"
              ><feather-icon
                style="cursor: pointer"
                icon="FilterIcon"
                size="15"
            /></b-button>
          </th>
          <th>Invoice Dt.</th>
          <th>
            Added Payment Dt.
            <!-- <hr /> -->

            <!-- <b-button
              @click="showModal"
              v-b-tooltip.hover
              title="apply filters"
              variant="secondary"
              size="sm"
              ><feather-icon
                style="cursor: pointer"
                icon="FilterIcon"
                size="15"
            /></b-button> -->
          </th>

          <th>Amount Received</th>
          <th>Currency</th>

          <th>Payment Created Dt.</th>
        </tr>
        <tr
          v-for="(item, index) in accountReceivableReportData"
          :key="index"
          :style="
            item.iban && item.iban.includes('Total')
              ? 'background:lightGrey;border-bottom:3px solid grey '
              : ''
          "
        >
          <td>{{ item.iban }}</td>
          <td>
            <a
              @click="
                () =>
                  (invId = item.revenue_invoice_id
                    ? item.revenue_invoice_id
                    : null)
              "
              v-b-modal.invModal
              ><u>{{ item.invoice_unique_id }}</u></a
            >
          </td>

          <td>{{ item.company }}</td>
          <td>
            {{ item.invDate ? item.invDate : "" }}
          </td>
          <td>{{ item.paidAmountDate ? item.paidAmountDate : "" }}</td>
          <td style="text-align: right">
            {{ formatCurrency(item.paidAmount) }}
          </td>
          <td>
            {{ item.selectedCurrency }}
          </td>

          <td>{{ item.paymentCreatedDate ? item.paymentCreatedDate : "" }}</td>
        </tr>
      </table>
    </div>
    <div>
      <b-modal
        no-close-on-backdrop
        ref="my-modal"
        hide-footer
        title="Report Filters"
      >
        <div class="d-block text-left">
          <b-form-group id="Account" label="Account">
            <v-select
              :close-on-select="true"
              multiple
              v-model="filters.selectedAccountArray"
              placeholder="Select"
              label="value"
              :reduce="(inv) => inv.id"
              :options="accountArray"
            />
          </b-form-group>
          <b-form-group id="Invoice" label="Invoice Numbers">
            <v-select
              :close-on-select="true"
              multiple
              v-model="filters.selectedInvoiceArray"
              placeholder="Select"
              label="value"
              :reduce="(inv) => inv.id"
              :options="invoiceNumbersArray"
            />
          </b-form-group>
          <b-form-group id="Licensor" label="Licensor">
            <v-select
              :close-on-select="true"
              multiple
              v-model="filters.selectedLicensorArray"
              placeholder="Select"
              label="value"
              :reduce="(inv) => inv.id"
              :options="licensorArray"
            />
          </b-form-group>
          <!-- <b-form-group label="Added Payment Date Range">
            <flat-pickr
              :config="{ mode: 'range', ...$flatPickrConfig }"
              placeholder="Select"
              class="form-control"
              v-model="filters.selectedInvDateRange"
            />
          </b-form-group> -->
          <b-form-group id="Currency" label="Currency">
            <v-select
              :close-on-select="true"
              multiple
              v-model="filters.selectedCurrencyArray"
              placeholder="Select"
              label="value"
              :reduce="(inv) => inv.id"
              :options="currencyArray"
            />
          </b-form-group>
        </div>
        <b-button
          class="mt-3"
          variant="outline-danger"
          block
          @click="onApplyFilters"
          >Apply Filters</b-button
        >
        <b-button variant="outline-info" block @click="onResetFilters"
          >Reset Filters</b-button
        >
      </b-modal>
    </div>
    <b-modal v-if="invId" size="xl" id="invModal" title="Invoice"
      ><preview-invoice :invId="invId" />
    </b-modal>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BButton,
  VBTooltip,
  BModal,
  BFormGroup,
  BBadge,
  BAlert,
} from "bootstrap-vue";
import { GetReceivedStatementReport } from "@/apiServices/BookkeepingServices";
import moment from "moment";
import { formatCurrency } from "@/utils/helpers";
import qs from "qs";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import PreviewInvoice from "@/views/pages/Revenue/PreviewInvoice.vue";
export default {
  data() {
    return {
      filterApplied: false,
      reportDateRange: null,
      reportFromDate: null,
      reportToDate: null,
      invoiceNumbersArray: [],
      licensorArray: [],
      currencyArray: [],
      accountArray: [],
      filters: {
        selectedAccountArray: [],
        selectedInvoiceArray: [],
        selectedLicensorArray: [],
        selectedCurrencyArray: [],

        selectedInvDateRange: null,
      },
      BASE_URL: process.env.VUE_APP_BASEURL,
      moment: moment,
      tabIndex: 0,
      accountReceivableReportData: [],
      actualAccountReceivableReportData: [],
      invId: null,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BTabs,
    BTab,
    BCard,
    FeatherIcon,
    BButton,
    VBTooltip,
    vSelect,
    BModal,
    flatPickr,
    BFormGroup,
    BBadge,
    PreviewInvoice,
    BAlert,
  },
  beforeMount() {
    this.filterApplied = false;
    let today = moment().format("YYYY-MM-DD");
    let previous = moment().startOf("month").format("YYYY-MM-DD");
    this.reportDateRange = previous + " to " + today;

    this.reportFromDate = moment().startOf("month").format("YYYY-MM-DD");
    this.reportToDate = moment().endOf("month").format("YYYY-MM-DD");
    this.getReceivedStatementReport();
  },
  methods: {
    formatCurrency,
    onReportDate() {
      if (this.reportDateRange) {
        let from = null;
        let to = null;
        if (this.reportDateRange.split("to").length > 1) {
          from = new Date(this.reportDateRange.split("to")[0]);
          to = new Date(this.reportDateRange.split("to")[1]);
        } else {
          from = new Date(this.reportDateRange.split("to")[0]);
          to = new Date(this.reportDateRange.split("to")[0]);
        }

        this.reportFromDate = `${from.getFullYear()}-${(
          "0" +
          (from.getMonth() + 1)
        ).slice(-2)}-${("0" + from.getDate()).slice(-2)}`;

        this.reportToDate = moment(to).add(1, "days").format("YYYY-MM-DD");

        this.getReceivedStatementReport();
      }

      //       const parsedDate = moment(dateString, 'MMM-YYYY');

      // // Step 2: Format the parsed date into YYYY-MM-DD format
      // const formattedDate = parsedDate.format('YYYY-MM-DD');
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    onExportExcel() {
      let url = null;
      let excelName = "";
      let payload = {
        accountReceivableReportData: this.accountReceivableReportData,
        invNumbers: this.filters.selectedInvoiceArray
          ? this.filters.selectedInvoiceArray.toString()
          : "",
        licensor: this.filters.selectedLicensorArray
          ? this.filters.selectedLicensorArray.toString()
          : "",
        invDtRange: this.filters.selectedInvDateRange,
        account: this.filters.selectedAccountArray
          ? this.filters.selectedAccountArray.toString()
          : "",
      };

      url = this.BASE_URL + `/bookkeeping/exportReceivedStatementReport`;

      fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/Json",
          authorization: localStorage.getItem("access_token"),
          "x-access-token": localStorage.getItem("access_token"),
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.blob())
        .then((blob) => {
          const anchorElement = document.createElement("a");
          document.body.appendChild(anchorElement);
          anchorElement.style.display = "none";
          excelName = "ReceivedStatementReport.xlsx";
          var url = window.URL.createObjectURL(blob);
          anchorElement.href = url;
          anchorElement.download = excelName;
          anchorElement.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err, "error in onExportExcel");
        });
    },
    async getReceivedStatementReport() {
      try {
        this.accountReceivableReportData = [];
        const response = await GetReceivedStatementReport({
          reportFromDate: this.reportFromDate,
          reportToDate: this.reportToDate,
        });
        if (response.data.status) {
          this.accountReceivableReportData = response.data.data;
          this.actualAccountReceivableReportData = response.data.data;

          let addedInv = [];
          let addedLicensor = [];
          let addedCurrency = [];
          let addedAcc = [];
          for (let item of this.accountReceivableReportData) {
            if (
              item.invoice_unique_id &&
              item.invoice_unique_id != "Total" &&
              item.invoice_unique_id != "Grand Total"
            ) {
              if (!addedInv.includes(item.invoice_unique_id)) {
                let obj = {
                  id: item.invoice_unique_id,
                  value: item.invoice_unique_id,
                };
                this.invoiceNumbersArray.push(obj);
                addedInv.push(item.invoice_unique_id);
              }
              if (!addedLicensor.includes(item.vendor_id)) {
                let obj1 = {
                  id: item.vendor_id,
                  value: item.company,
                };
                this.licensorArray.push(obj1);
                addedLicensor.push(item.vendor_id);
              }
              if (item.selectedCurrency) {
                if (!addedCurrency.includes(item.selectedCurrency)) {
                  let obj2 = {
                    id: item.selectedCurrency,
                    value: item.selectedCurrency,
                  };
                  this.currencyArray.push(obj2);
                  addedCurrency.push(item.selectedCurrency);
                }
              }

              if (item.iban) {
                if (!addedAcc.includes(item.iban)) {
                  let obj3 = {
                    id: item.iban,
                    value: item.iban,
                  };
                  this.accountArray.push(obj3);
                  addedAcc.push(item.iban);
                }
              }
            }
          }
        }
        console.log(response, "response");
      } catch (error) {
        console.log("Error in getReceivedStatementReport", error);
      }
    },
    onResetFilters() {
      this.filterApplied = false;
      this.invoiceNumbersArray = [];
      this.licensorArray = [];
      this.currencyArray = [];
      this.accountArray = [];
      this.filters = {
        selectedInvoiceArray: [],
        selectedLicensorArray: [],
        selectedCurrencyArray: [],
        selectedInvDateRange: null,
        selectedAccountArray: null,
      };
      this.getReceivedStatementReport();
    },
    onApplyFilters() {
      this.filterApplied = true;
      this.accountReceivableReportData = this.actualAccountReceivableReportData;
      let value = "";
      if (this.filters.selectedInvoiceArray.length) {
        this.accountReceivableReportData =
          this.accountReceivableReportData.filter((p) => {
            return this.filters.selectedInvoiceArray.includes(
              p.invoice_unique_id
            );
          });
      }

      if (this.filters.selectedAccountArray.length) {
        this.accountReceivableReportData =
          this.accountReceivableReportData.filter((p) => {
            return this.filters.selectedAccountArray.includes(p.iban);
          });
      }

      if (this.filters.selectedLicensorArray.length) {
        this.accountReceivableReportData =
          this.accountReceivableReportData.filter((p) => {
            return this.filters.selectedLicensorArray.includes(p.vendor_id);
          });
      }

      if (this.filters.selectedCurrencyArray.length) {
        this.accountReceivableReportData =
          this.accountReceivableReportData.filter((p) => {
            if (p.selectedCurrency) {
              return this.filters.selectedCurrencyArray.includes(
                p.selectedCurrency
              );
            }
          });
      }

      if (this.filters.selectedInvDateRange) {
        if (this.filters.selectedInvDateRange.includes("to")) {
          let fromDate = this.filters.selectedInvDateRange.split("to")[0];
          let toDate = this.filters.selectedInvDateRange.split("to")[1];

          this.accountReceivableReportData =
            this.accountReceivableReportData.filter((p) => {
              if (p.paidAmountDt) {
                console.log(
                  new Date(p.paidAmountDt).getTime() >=
                    new Date(fromDate).getTime() &&
                    new Date(p.paidAmountDt).getTime() <=
                      new Date(toDate).getTime()
                );
                return (
                  new Date(p.paidAmountDt).getTime() >=
                    new Date(fromDate).getTime() &&
                  new Date(p.paidAmountDt).getTime() <=
                    new Date(toDate).getTime()
                );
              }
            });
        } else {
          let dt = this.filters.selectedInvDateRange;
          this.accountReceivableReportData =
            this.accountReceivableReportData.filter((p) => {
              return moment(p.paidAmountDate) == moment(dt);
            });
        }
      }

      let result = this.accountReceivableReportData.filter(
        (payment) => payment.iban && payment.paidAmount
      );

      let finalResult = [];

      let prev_company = result[0].iban;
      let total = 0;
      let grandTotal = 0;
      let grandTotalUsd = 0;
      let grandTotalEur = 0;

      for (let payment of result) {
        let current_company = payment.iban;
        if (current_company == prev_company) {
          total += payment.paidAmount ? parseFloat(payment.paidAmount) : 0;

          if (payment.selectedCurrency == "CHF") {
            grandTotal += payment.paidAmount
              ? parseFloat(payment.paidAmount)
              : 0;
          } else if (payment.selectedCurrency == "USD") {
            grandTotalUsd += payment.paidAmount
              ? parseFloat(payment.paidAmount)
              : 0;
          } else if (payment.selectedCurrency == "EUR") {
            grandTotalEur += payment.paidAmount
              ? parseFloat(payment.paidAmount)
              : 0;
          } else {
            console.log("new currency");
          }

          finalResult.push(payment);
        } else {
          let totalPayment = {
            iban: "Total " + prev_company,
            paidAmount: total,
          };
          finalResult.push(totalPayment);
          // for leaving lines

          finalResult.push(payment);
          prev_company = payment.iban;
          total = parseFloat(payment.paidAmount);
          if (payment.selectedCurrency == "CHF") {
            grandTotal += parseFloat(payment.paidAmount);
          } else if (payment.selectedCurrency == "USD") {
            grandTotalUsd += parseFloat(payment.paidAmount);
          } else if (payment.selectedCurrency == "EUR") {
            grandTotalEur += parseFloat(payment.paidAmount);
          } else {
            console.log("new currency");
          }
        }
      }

      let totalPayment = { iban: "Total " + prev_company, paidAmount: total };
      finalResult.push(totalPayment);

      let finalGrandTotalCHF = {
        iban: "Grand Total CHF",
        paidAmount: grandTotal,
      };
      let finalGrandTotalUSD = {
        iban: "Grand Total USD",
        paidAmount: grandTotalUsd,
      };
      let finalGrandTotalEUR = {
        iban: "Grand Total EUR",
        paidAmount: grandTotalEur,
      };
      finalResult.push(finalGrandTotalCHF);
      finalResult.push(finalGrandTotalUSD);
      finalResult.push(finalGrandTotalEUR);

      console.log(finalResult, "finalResult");

      this.accountReceivableReportData = finalResult;

      this.$refs["my-modal"].hide();
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-primary", "text-light"];
      } else {
        return ["bg-outline-primary", "text-info"];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  color: #000;
  border: 2px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-weight: 400;
}
tr:hover {
  background-color: yellow;
}
</style>
