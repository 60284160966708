<template>
  <div>
    <b-alert variant="secondary" show>
      <div
        style="display: flex; align-items: end; justify-content: end"
        class="mb-1 alert-heading"
      >
        <p class="mr-1"><b>Account Receivable (to date) :</b></p>
        <flat-pickr
          @input="onReportDate"
          v-model="reportToDate"
          style="width: 400px !important"
          :config="{ ...$flatPickrConfig }"
          placeholder="Select Month Range"
          class="form-control mr-1"
        />

        <b-button
          @click="showModal"
          class="mr-1"
          v-b-tooltip.hover
          title="apply filters"
          variant="secondary"
          size="sm"
          ><feather-icon style="cursor: pointer" icon="FilterIcon" size="23"
        /></b-button>
        <b-button
          v-b-tooltip.hover
          title="export as excel"
          variant="secondary"
          size="sm"
          @click="onExportExcel"
          ><feather-icon style="cursor: pointer" icon="DownloadIcon" size="23"
        /></b-button>
      </div>
    </b-alert>
    <div v-if="filterApplied == true">
      <b-badge
        class="mr-1"
        variant="secondary"
        v-if="filters.selectedInvoiceArray.length"
      >
        Invoice No : {{ filters.selectedInvoiceArray.toString() }}
      </b-badge>
      <b-badge
        class="mr-1"
        variant="secondary"
        v-if="filters.selectedLicensorArray.length"
      >
        Licensor : {{ filters.selectedLicensorArray.toString() }}
      </b-badge>
      <b-badge
        class="mr-1"
        variant="secondary"
        v-if="filters.selectedInvDateRange"
      >
        Invoice Date : {{ filters.selectedInvDateRange }}
      </b-badge>
      <b-badge
        class="mr-1"
        variant="secondary"
        v-if="filters.selectedCurrencyArray.length"
      >
        Invoice Currency : {{ filters.selectedCurrencyArray.toString() }}
      </b-badge>
    </div>
    <div style="overflow-x: auto">
      <table ref="exportable_table">
        <tr style="background: lightgrey">
          <th>
            Invoice Number
            <hr />

            <b-button
              @click="showModal"
              v-b-tooltip.hover
              title="apply filters"
              variant="secondary"
              size="sm"
              ><feather-icon
                style="cursor: pointer"
                icon="FilterIcon"
                size="15"
            /></b-button>
          </th>
          <th>
            Account
            <hr />

            <b-button
              @click="showModal"
              v-b-tooltip.hover
              title="apply filters"
              variant="secondary"
              size="sm"
              ><feather-icon
                style="cursor: pointer"
                icon="FilterIcon"
                size="15"
            /></b-button>
          </th>
          <th>
            Licensor
            <hr />
            <b-button
              @click="showModal"
              v-b-tooltip.hover
              title="apply filters"
              variant="secondary"
              size="sm"
              ><feather-icon
                style="cursor: pointer"
                icon="FilterIcon"
                size="15"
            /></b-button>
          </th>
          <th>
            Invoice Date
            <!-- <hr />
            <b-button
              @click="showModal"
              v-b-tooltip.hover
              title="apply filters"
              variant="warning"
              size="sm"
              ><feather-icon
                style="cursor: pointer"
                icon="FilterIcon"
                size="15"
            /></b-button> -->
          </th>
          <th>
            Invoice Currency
            <hr />
            <b-button
              @click="showModal"
              v-b-tooltip.hover
              title="apply filters"
              variant="secondary"
              size="sm"
              ><feather-icon
                style="cursor: pointer"
                icon="FilterIcon"
                size="15"
            /></b-button>
          </th>
          <th style="border-left: 2.5px solid grey">Total Amount CHF</th>
          <th>Paid Amount CHF</th>
          <th>Storno Amount CHF</th>
          <th style="border-right: 2.5px solid grey">Unpaid Amount CHF</th>
          <th>Total Amount EUR</th>
          <th>Paid Amount EUR</th>
          <th>Storno Amount EUR</th>
          <th style="border-right: 2.5px solid grey">Unpaid Amount EUR</th>
          <th>Total Amount USD</th>
          <th>Paid Amount USD</th>
          <th>Storno Amount USD</th>
          <th>Unpaid Amount USD</th>
        </tr>
        <tr
          v-for="(item, index) in accountReceivableReportData"
          :key="index"
          :style="
            item.invoice_unique_id == 'Total'
              ? 'background:lightGrey;border-bottom:2.5px solid grey '
              : ''
          "
        >
          <td>
            <a
              @click="
                () =>
                  (invId = item.revenue_invoice_id
                    ? item.revenue_invoice_id
                    : null)
              "
              v-b-modal.invModal
              ><u>{{ item.invoice_unique_id }}</u></a
            >
          </td>
          <td>{{ item.iban }}</td>
          <td>{{ item.company }}</td>
          <td>
            {{ item.invoiceDate ? item.invoiceDate : "" }}
          </td>
          <td>{{ item.selectedCurrency }}</td>
          <td
            :style="
              item.total_amt_chf != 0
                ? 'text-align: right; background: lightgreen'
                : 'text-align: right;'
            "
            style="border-left: 2.5px solid grey"
          >
            {{ formatCurrency(item.total_amt_chf) }}
          </td>
          <td
            :style="
              item.paid_amt_chf != 0
                ? 'text-align: right; background: lightgreen'
                : 'text-align: right;'
            "
            style="text-align: right"
          >
            {{ formatCurrency(item.paid_amt_chf) }}
          </td>
          <td
            :style="
              item.storno_amt_chf != 0
                ? 'text-align: right; background: lightgreen'
                : 'text-align: right;'
            "
            style="text-align: right"
          >
            {{ formatCurrency(item.storno_amt_chf) }}
          </td>
          <td
            :style="
              item.unpaid_amt_chf != 0
                ? 'text-align: right; background: lightgreen'
                : 'text-align: right;'
            "
            style="text-align: right; border-right: 2.5px solid grey"
          >
            {{ formatCurrency(item.unpaid_amt_chf) }}
          </td>
          <td
            :style="
              item.total_amt_eur != 0
                ? 'text-align: right; background: lightgreen'
                : 'text-align: right;'
            "
            style="text-align: right"
          >
            {{ formatCurrency(item.total_amt_eur) }}
          </td>
          <td
            :style="
              item.paid_amt_eur != 0
                ? 'text-align: right; background: lightgreen'
                : 'text-align: right;'
            "
            style="text-align: right"
          >
            {{ formatCurrency(item.paid_amt_eur) }}
          </td>
          <td
            :style="
              item.storno_amt_eur != 0
                ? 'text-align: right; background: lightgreen'
                : 'text-align: right;'
            "
            style="text-align: right"
          >
            {{ formatCurrency(item.storno_amt_eur) }}
          </td>
          <td
            :style="
              item.unpaid_amt_eur != 0
                ? 'text-align: right; background: lightgreen'
                : 'text-align: right;'
            "
            style="text-align: right; border-right: 2.5px solid grey"
          >
            {{ formatCurrency(item.unpaid_amt_eur) }}
          </td>
          <td
            :style="
              item.total_amt_usd != 0
                ? 'text-align: right; background: lightgreen'
                : 'text-align: right;'
            "
            style="text-align: right"
          >
            {{ formatCurrency(item.total_amt_usd) }}
          </td>
          <td
            :style="
              item.paid_amt_usd != 0
                ? 'text-align: right; background: lightgreen'
                : 'text-align: right;'
            "
            style="text-align: right"
          >
            {{ formatCurrency(item.paid_amt_usd) }}
          </td>
          <td
            :style="
              item.storno_amt_usd != 0
                ? 'text-align: right; background: lightgreen'
                : 'text-align: right;'
            "
            style="text-align: right"
          >
            {{ formatCurrency(item.storno_amt_usd) }}
          </td>
          <td
            :style="
              item.unpaid_amt_usd != 0
                ? 'text-align: right; background: lightgreen'
                : 'text-align: right;'
            "
            style="text-align: right"
          >
            {{ formatCurrency(item.unpaid_amt_usd) }}
          </td>
        </tr>
      </table>
    </div>
    <div>
      <b-modal
        no-close-on-backdrop
        ref="my-modal"
        hide-footer
        title="Report Filters"
      >
        <div class="d-block text-left">
          <b-form-group id="Invoice" label="Invoice Numbers">
            <v-select
              :close-on-select="true"
              multiple
              v-model="filters.selectedInvoiceArray"
              placeholder="Select"
              label="value"
              :reduce="(inv) => inv.id"
              :options="invoiceNumbersArray"
            />
          </b-form-group>
          <b-form-group id="Account" label="Account">
            <v-select
              :close-on-select="true"
              multiple
              v-model="filters.selectedaccArray"
              placeholder="Select"
              label="value"
              :reduce="(inv) => inv.id"
              :options="accNumbersArray"
            />
          </b-form-group>
          <b-form-group id="Licensor" label="Licensor">
            <v-select
              :close-on-select="true"
              multiple
              v-model="filters.selectedLicensorArray"
              placeholder="Select"
              label="value"
              :reduce="(inv) => inv.id"
              :options="licensorArray"
            />
          </b-form-group>
          <!-- <b-form-group label="Invoice Date Range">
            <flat-pickr
              :config="{ mode: 'range', ...$flatPickrConfig }"
              placeholder="Select"
              class="form-control"
              v-model="filters.selectedInvDateRange"
            />
          </b-form-group> -->
          <b-form-group id="Currency" label="Currency">
            <v-select
              :close-on-select="true"
              multiple
              v-model="filters.selectedCurrencyArray"
              placeholder="Select"
              label="value"
              :reduce="(inv) => inv.id"
              :options="currencyArray"
            />
          </b-form-group>
        </div>
        <b-button
          class="mt-3"
          variant="outline-danger"
          block
          @click="onApplyFilters"
          >Apply Filters</b-button
        >
        <b-button variant="outline-info" block @click="onResetFilters"
          >Reset Filters</b-button
        >
      </b-modal>
    </div>
    <b-modal v-if="invId" size="xl" id="invModal" title="Invoice"
      ><preview-invoice :invId="invId" />
    </b-modal>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BButton,
  VBTooltip,
  BFormGroup,
  BBadge,
  BAlert,
} from "bootstrap-vue";
import { GetAccountReceivableReport } from "@/apiServices/BookkeepingServices";
import moment from "moment";
import { formatCurrency } from "@/utils/helpers";
import qs from "qs";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import PreviewInvoice from "@/views/pages/Revenue/PreviewInvoice.vue";

export default {
  data() {
    return {
      reportDateRange: null,
      reportFromDate: null,
      reportToDate: null,
      invoiceNumbersArray: [],
      accNumbersArray: [],
      licensorArray: [],
      currencyArray: [],
      filters: {
        selectedInvoiceArray: [],
        selectedaccArray: [],
        selectedLicensorArray: [],
        selectedCurrencyArray: [],

        selectedInvDateRange: null,
      },
      BASE_URL: process.env.VUE_APP_BASEURL,
      moment: moment,
      tabIndex: 0,
      accountReceivableReportData: [],
      actualAccountReceivableReportData: [],
      filterApplied: false,
      invId: null,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BTabs,
    BTab,
    BCard,
    FeatherIcon,
    BButton,
    VBTooltip,
    vSelect,
    BFormGroup,
    flatPickr,
    BBadge,
    PreviewInvoice,
    BAlert,
  },
  beforeMount() {
    this.filterApplied = false;
    this.reportToDate = moment().format("YYYY-MM-DD");
    this.getAccountReceivableReport();
  },
  methods: {
    formatCurrency,
    onReportDate() {
      // if (this.reportDateRange) {
      //   let from = null;
      //   let to = null;
      //   if (this.reportDateRange.split("to").length > 1) {
      //     from = new Date(this.reportDateRange.split("to")[0]);
      //     to = new Date(this.reportDateRange.split("to")[1]);
      //   } else {
      //     from = new Date(this.reportDateRange.split("to")[0]);
      //     to = new Date(this.reportDateRange.split("to")[0]);
      //   }

      //   this.reportFromDate = `${from.getFullYear()}-${(
      //     "0" +
      //     (from.getMonth() + 1)
      //   ).slice(-2)}-${("0" + from.getDate()).slice(-2)}`;

      //   this.reportToDate = `${to.getFullYear()}-${(
      //     "0" +
      //     (to.getMonth() + 1)
      //   ).slice(-2)}-${(
      //     "0" + (to.getDate() + to.getMonth() == 2 ? 28 : 30)
      //   ).slice(-2)}`;

      this.getAccountReceivableReport();
      // }

      //       const parsedDate = moment(dateString, 'MMM-YYYY');

      // // Step 2: Format the parsed date into YYYY-MM-DD format
      // const formattedDate = parsedDate.format('YYYY-MM-DD');
    },
    onExportExcel() {
      let url = null;
      let excelName = "";
      let payload = {
        accountReceivableReportData: this.accountReceivableReportData,
        invNumbers: this.filters.selectedInvoiceArray
          ? this.filters.selectedInvoiceArray.toString()
          : "",
        accNumber: this.filters.selectedaccArray
          ? this.filters.selectedaccArray.toString()
          : "",

        licensor: this.filters.selectedLicensorArray
          ? this.filters.selectedLicensorArray.toString()
          : "",
        invDtRange: this.filters.selectedInvDateRange,
        currency: this.filters.selectedCurrencyArray
          ? this.filters.selectedCurrencyArray.toString()
          : "",
      };

      url = this.BASE_URL + `/bookkeeping/exportAccountReceivableReport`;

      fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/Json",
          authorization: localStorage.getItem("access_token"),
          "x-access-token": localStorage.getItem("access_token"),
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.blob())
        .then((blob) => {
          const anchorElement = document.createElement("a");
          document.body.appendChild(anchorElement);
          anchorElement.style.display = "none";
          excelName = "Account_Recievable_Report.xlsx";
          var url = window.URL.createObjectURL(blob);
          anchorElement.href = url;
          anchorElement.download = excelName;
          anchorElement.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err, "error in onExportExcel");
        });
    },
    async getAccountReceivableReport() {
      try {
        this.accountReceivableReportData = [];
        this.actualAccountReceivableReportData = [];
        const response = await GetAccountReceivableReport({
          //  reportFromDate: this.reportFromDate,
          reportToDate: this.reportToDate,
        });
        if (response.data.status) {
          this.actualAccountReceivableReportData = response.data.data;
          this.accountReceivableReportData = response.data.data;
          let addedInv = [];
          let addedLicensor = [];
          let addedCurrency = [];
          let addedAcc = [];
          for (let item of this.accountReceivableReportData) {
            if (
              item.invoice_unique_id &&
              item.invoice_unique_id != "Total" &&
              item.invoice_unique_id != "Grand Total"
            ) {
              if (!addedInv.includes(item.invoice_unique_id)) {
                let obj = {
                  id: item.invoice_unique_id,
                  value: item.invoice_unique_id,
                };
                this.invoiceNumbersArray.push(obj);
                addedInv.push(item.invoice_unique_id);
              }

              if (!addedAcc.includes(item.iban)) {
                let obj66 = {
                  id: item.iban,
                  value: item.iban,
                };
                this.accNumbersArray.push(obj66);
                addedAcc.push(item.iban);
              }

              if (!addedLicensor.includes(item.company)) {
                let obj1 = {
                  id: item.company,
                  value: item.company,
                };
                this.licensorArray.push(obj1);
                addedLicensor.push(item.company);
              }
              if (item.selectedCurrency) {
                if (!addedCurrency.includes(item.selectedCurrency)) {
                  let obj2 = {
                    id: item.selectedCurrency,
                    value: item.selectedCurrency,
                  };
                  this.currencyArray.push(obj2);
                  addedCurrency.push(item.selectedCurrency);
                }
              }
            }
          }
        }
        console.log(response, "response");
      } catch (error) {
        console.log("Error in getAccountReceivableReport", error);
      }
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    onResetFilters() {
      this.filterApplied = false;
      this.invoiceNumbersArray = [];
      this.accNumbersArray = [];
      this.licensorArray = [];
      this.currencyArray = [];
      this.filters = {
        selectedInvoiceArray: [],
        selectedaccArray: [],
        selectedLicensorArray: [],
        selectedCurrencyArray: [],
        selectedInvDateRange: null,
      };
      this.getAccountReceivableReport();
    },
    onApplyFilters() {
      this.filterApplied = true;
      this.accountReceivableReportData = this.actualAccountReceivableReportData;
      let value = "";
      if (this.filters.selectedInvoiceArray.length) {
        this.accountReceivableReportData =
          this.accountReceivableReportData.filter((p) => {
            return this.filters.selectedInvoiceArray.includes(
              p.invoice_unique_id
            );
          });
      }
      if (this.filters.selectedaccArray.length) {
        this.accountReceivableReportData =
          this.accountReceivableReportData.filter((p) => {
            return this.filters.selectedaccArray.includes(p.iban);
          });
      }

      if (this.filters.selectedLicensorArray.length) {
        this.accountReceivableReportData =
          this.accountReceivableReportData.filter((p) => {
            return this.filters.selectedLicensorArray.includes(p.company);
          });
      }

      if (this.filters.selectedCurrencyArray.length) {
        this.accountReceivableReportData =
          this.accountReceivableReportData.filter((p) => {
            if (p.selectedCurrency) {
              return this.filters.selectedCurrencyArray.includes(
                p.selectedCurrency
              );
            }
          });
      }

      if (this.filters.selectedInvDateRange) {
        if (this.filters.selectedInvDateRange.includes("to")) {
          let fromDate = this.filters.selectedInvDateRange.split("to")[0];
          let toDate = this.filters.selectedInvDateRange.split("to")[1];

          this.accountReceivableReportData =
            this.accountReceivableReportData.filter((p) => {
              return (
                p.invoiceDate >=
                  moment(new Date(fromDate)).format("DD/MM/YYYY") &&
                p.invoiceDate <= moment(new Date(toDate)).format("DD/MM/YYYY")
              );
            });
        } else {
          let dt = this.filters.selectedInvDateRange;
          this.accountReceivableReportData =
            this.accountReceivableReportData.filter((p) => {
              return p.invoiceDate == moment(new Date(dt)).format("DD/MM/YYYY");
            });
        }
      }

      let dataArray = [];
      let invoice_total_grand = 0;
      let received_amount_grand = 0;
      let strono_credit_grand = 0;
      let unpaid_grand = 0;

      let invoice_total_grand_usd = 0;
      let received_amount_grand_usd = 0;
      let strono_credit_grand_usd = 0;
      let unpaid_grand_usd = 0;

      let invoice_total_grand_eur = 0;
      let received_amount_grand_eur = 0;
      let strono_credit_grand_eur = 0;
      let unpaid_grand_eur = 0;
      let arrayVendors = [];
      for (let vendor of this.accountReceivableReportData) {
        let index = arrayVendors.findIndex((x) => x.company == vendor.company);
        if (index >= 0) {
          arrayVendors[index].data.push(vendor);
        } else {
          arrayVendors.push({
            company: vendor.company,
            data: [vendor],
          });
        }
      }

      for (let item of arrayVendors) {
        // console.log(item, 'item');
        let invoice_total_final_chf = 0;
        let received_amount_final_chf = 0;
        let strono_credit_final_chf = 0;
        let unpaid_final_chf = 0;

        let invoice_total_final_eur = 0;
        let received_amount_final_eur = 0;
        let strono_credit_final_eur = 0;
        let unpaid_final_eur = 0;

        let invoice_total_final_usd = 0;
        let received_amount_final_usd = 0;
        let strono_credit_final_usd = 0;
        let unpaid_final_usd = 0;
        for (let child of item.data) {
          child.invoice_total = child.invoice_total ? child.invoice_total : 0;
          child.received_amount = child.received_amount
            ? child.received_amount
            : 0;
          child.strono_credit = child.strono_credit ? child.strono_credit : 0;
          child.unpaid_amt = child.unpaid_amt ? child.unpaid_amt : 0;
          child["total_amt_chf"] = 0;
          child["paid_amt_chf"] = 0;
          child["storno_amt_chf"] = 0;
          child["unpaid_amt_chf"] = 0;
          child["total_amt_eur"] = 0;
          child["paid_amt_eur"] = 0;
          child["storno_amt_eur"] = 0;
          child["unpaid_amt_eur"] = 0;
          child["total_amt_usd"] = 0;
          child["paid_amt_usd"] = 0;
          child["storno_amt_usd"] = 0;
          child["unpaid_amt_usd"] = 0;

          if (child.selectedCurrency == "CHF") {
            child["total_amt_chf"] = parseFloat(child.invoice_total);
            invoice_total_final_chf += parseFloat(child["total_amt_chf"]);

            child["paid_amt_chf"] =
              child.received_amount && child.strono_credit_canceled == 0
                ? parseFloat(child.received_amount)
                : 0;
            received_amount_final_chf += parseFloat(child["paid_amt_chf"]);

            child["storno_amt_chf"] =
              child.strono_credit_canceled == 1
                ? parseFloat(child.invoice_total)
                : 0;
            strono_credit_final_chf += parseFloat(child["storno_amt_chf"]);

            child["unpaid_amt_chf"] =
              child.strono_credit_canceled == 1
                ? 0
                : parseFloat(child.invoice_total) -
                  parseFloat(child.received_amount);
            unpaid_final_chf += parseFloat(child["unpaid_amt_chf"]);

            invoice_total_grand += parseFloat(child["total_amt_chf"]);
            received_amount_grand += parseFloat(child["paid_amt_chf"]);
            strono_credit_grand += parseFloat(child["storno_amt_chf"]);
            unpaid_grand += parseFloat(child["unpaid_amt_chf"]);
          } else if (child.selectedCurrency == "USD") {
            child["total_amt_usd"] = parseFloat(child.invoice_total);
            invoice_total_final_usd += parseFloat(child["total_amt_usd"]);

            child["paid_amt_usd"] =
              child.received_amount && child.strono_credit_canceled == 0
                ? parseFloat(child.received_amount)
                : 0;
            received_amount_final_usd += parseFloat(child["paid_amt_usd"]);

            child["storno_amt_usd"] =
              child.strono_credit_canceled == 1
                ? parseFloat(child.invoice_total)
                : 0;
            strono_credit_final_usd += parseFloat(child["storno_amt_usd"]);

            child["unpaid_amt_usd"] =
              child.strono_credit_canceled == 1
                ? 0
                : parseFloat(child.invoice_total) -
                  parseFloat(child.received_amount);
            unpaid_final_usd += parseFloat(child["unpaid_amt_usd"]);

            invoice_total_grand_usd += parseFloat(child["total_amt_usd"]);
            received_amount_grand_usd += parseFloat(child["paid_amt_usd"]);
            strono_credit_grand_usd += parseFloat(child["storno_amt_usd"]);
            unpaid_grand_usd += parseFloat(child["unpaid_amt_usd"]);
          } else if (child.selectedCurrency == "EUR") {
            child["total_amt_eur"] = parseFloat(child.invoice_total);
            invoice_total_final_eur += parseFloat(child["total_amt_eur"]);

            child["paid_amt_eur"] =
              child.received_amount && child.strono_credit_canceled == 0
                ? parseFloat(child.received_amount)
                : 0;
            received_amount_final_eur += parseFloat(child["paid_amt_eur"]);

            child["storno_amt_eur"] =
              child.strono_credit_canceled == 1
                ? parseFloat(child.invoice_total)
                : 0;
            strono_credit_final_eur += parseFloat(child["storno_amt_eur"]);

            child["unpaid_amt_eur"] =
              child.strono_credit_canceled == 1
                ? 0
                : parseFloat(child.invoice_total) -
                  parseFloat(child.received_amount);
            unpaid_final_eur += parseFloat(child["unpaid_amt_eur"]);

            invoice_total_grand_eur += parseFloat(child["total_amt_eur"]);
            received_amount_grand_eur += parseFloat(child["paid_amt_eur"]);
            strono_credit_grand_eur += parseFloat(child["storno_amt_eur"]);
            unpaid_grand_eur += parseFloat(child["unpaid_amt_eur"]);
          } else {
            console.log("new currency");
          }
          dataArray.push(child);
        }

        let totalSection = {
          invoice_unique_id: "Total",
          company: item.company,
          createdAt: "",
          total_amt_chf: invoice_total_final_chf,
          paid_amt_chf: received_amount_final_chf,
          storno_amt_chf: strono_credit_final_chf,
          unpaid_amt_chf: unpaid_final_chf,
          total_amt_eur: invoice_total_final_eur,
          paid_amt_eur: received_amount_final_eur,
          storno_amt_eur: strono_credit_final_eur,
          unpaid_amt_eur: unpaid_final_eur,
          total_amt_usd: invoice_total_final_usd,
          paid_amt_usd: received_amount_final_usd,
          storno_amt_usd: strono_credit_final_usd,
          unpaid_amt_usd: unpaid_final_usd,
        };
        dataArray.push(totalSection);
      }

      let grandSection = {
        invoice_unique_id: "Grand Total",
        total_amt_chf: invoice_total_grand,
        paid_amt_chf: received_amount_grand,
        storno_amt_chf: strono_credit_grand,
        unpaid_amt_chf: unpaid_grand,

        total_amt_eur: invoice_total_grand_eur,
        paid_amt_eur: received_amount_grand_eur,
        storno_amt_eur: strono_credit_grand_eur,
        unpaid_amt_eur: unpaid_grand_eur,

        total_amt_usd: invoice_total_grand_usd,
        paid_amt_usd: received_amount_grand_usd,
        storno_amt_usd: strono_credit_grand_usd,
        unpaid_amt_usd: unpaid_grand_usd,
      };
      dataArray.push(grandSection);

      this.accountReceivableReportData = dataArray;
      // moment(p.invoiceDate).format("DD/MM/YYYY") ==
      //   moment(dt).format("DD/MM/YYYY");

      this.$refs["my-modal"].hide();
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-primary", "text-light"];
      } else {
        return ["bg-outline-primary", "text-info"];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  color: #000;
  border: 2px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-weight: 400;
}
tr:hover {
  background-color: yellow;
}
</style>
