import Api from "./Api";

export const GetAccountReceivableReport = async (params) =>
  Api().get(`bookkeeping/getAccountReceivableReport`, { params });

export const GetBookKeepingRevenueReport = async (params) =>
  Api().get(`bookkeeping/GetBookKeepingRevenueReport`, { params });

export const GetReceivedStatementReport = async (params) =>
  Api().get(`bookkeeping/getReceivedStatementReport`, { params });
export const GetAdditionalRevBookKeepingRevenueReport = async (params) =>
  Api().get(`bookkeeping/getAdditionalRevBookKeepingRevenueReport`, { params });
