<template>
  <div>
    <b-tabs align="left" pills v-model="tabIndex">
      <b-tab title="Account Receivable Report" :title-link-class="linkClass(0)">
        <account-receivable-report v-if="tabIndex == 0" />
      </b-tab>
      <b-tab title="Received Statement Report" :title-link-class="linkClass(1)"
        ><received-statement-report v-if="tabIndex == 1"
      /></b-tab>
      <b-tab title="Bookkeeping Revenue Report" :title-link-class="linkClass(2)"
        ><bookkeeping-revenue-report v-if="tabIndex == 2"
      /></b-tab>
      <b-tab
        title="Additional Revenue Bookkeeping  Report"
        :title-link-class="linkClass(3)"
        ><add-rev-bookkeeping-revenue-report v-if="tabIndex == 3"
      /></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BButton, VBTooltip } from "bootstrap-vue";

import moment from "moment";
import { formatCurrency } from "@/utils/helpers";
import qs from "qs";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import AccountReceivableReport from "./AccountReceivableReport.vue";
import ReceivedStatementReport from "./ReceivedStatementReport.vue";
import BookkeepingRevenueReport from "./BookkeepingRevenueReport.vue";
import AddRevBookkeepingRevenueReport from "./AddRevBookkeepingRevenueReport.vue";
export default {
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      moment: moment,
      tabIndex: 0,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BTabs,
    BTab,
    BCard,
    FeatherIcon,
    BButton,
    VBTooltip,
    AccountReceivableReport,
    ReceivedStatementReport,
    BookkeepingRevenueReport,
    AddRevBookkeepingRevenueReport,
  },
  beforeMount() {},
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-primary", "text-light"];
      } else {
        return ["bg-outline-primary", "text-info"];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 2px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-weight: 550;
}
tr:hover {
  background-color: yellow;
}
</style>
