<template>
  <b-sidebar
    id="sidebar-send-invoice"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          {{
            type && type == "RevenueInvEmail"
              ? "Send Invoice"
              : type && type == "RoyaltyInvEmail"
              ? "Send Report"
              : "Send"
          }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <b-form class="p-2" @submit.prevent>
        <!-- From -->
        <b-form-group label="From" label-for="from">
          <b-form-input
            id="from"
            v-model="sendInvoiceData.from"
            trim
            type="email"
          />
        </b-form-group>

        <!-- To -->
        <b-form-group label="To" label-for="to">
          <b-form-input
            id="to"
            v-model="sendInvoiceData.to"
            trim
            type="email"
          />
        </b-form-group>

        <!-- Subject -->
        <b-form-group label="Subject" label-for="subject">
          <b-form-input id="subject" v-model="sendInvoiceData.subject" trim />
        </b-form-group>

        <!-- Message -->
        <b-form-group label="Message" label-for="message">
          <b-form-textarea
            id="message"
            v-model="sendInvoiceData.message"
            rows="12"
            trim
          />
        </b-form-group>

        <!-- ? File/Attachement -->
        <b-badge variant="light-primary">
          <span class="ml-50">*Document will be auto sent as attachment</span>
        </b-badge>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            :disabled="
              !sendInvoiceData.from ||
              !sendInvoiceData.subject ||
              !sendInvoiceData.to ||
              !sendInvoiceData.message ||
              loading
                ? true
                : false
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="send"
          >
            <b-spinner v-if="loading" small /> Send
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { SendInvoiceEmail } from "@/apiServices/RevenueServies";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: {
    id: String,
    type: String,
  },

  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BBadge,
    BSpinner,
    ToastificationContentVue,
  },
  directives: {
    Ripple,
  },
  setup() {
    const sendInvoiceData = ref({
      from: "yusuf.borkar@enpointe.io",
      to: "",
      subject: "",
      message: "",
      // ? Need to handle how you want to send item
      // * You can also send link to download invoice in message
    });

    return {
      sendInvoiceData,
      loading: false,
    };
  },
  methods: {
    async send() {
      this.loading = true;
      try {
        let payload = {
          sendInvoiceData: this.sendInvoiceData,
          id: this.id,
          type: this.type,
        };
        const response = await SendInvoiceEmail(payload);
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Email Sent Successfully",
              icon: "EditIcon",
              variant: "primary",
            },
          });
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("Email Sent", err);
      }
    },
  },
};
</script>

<style></style>
